<template>
    <div>
        <button type="button" @click="test" class="btn btn-sm btn-dark  ">test</button>
    </div>
</template>
<script>
import axios from 'axios'
export default{
    data(){
        return{
            notif:true
        }
    },
    methods: {
    async sendNotif(txt,target){
        let that=this;
        if(this.notif){
            if(target=='Dokter'){ // GROUP DOKTER
            await axios.post('https://api.telegram.org/bot1524816097:AAEg9TRy49FIBAO2jsy_tGSUJjcqILL4Jbk/sendMessage',{ chat_id:"-1001797459274", text:txt })
          }else if(target=='Nurse'){ // GROUP NURSE
            await axios.post('https://api.telegram.org/bot1524816097:AAEg9TRy49FIBAO2jsy_tGSUJjcqILL4Jbk/sendMessage',{ chat_id:"-1001771828590", text:txt })
          }else if(target=='Facial'){ // GROUP FACIAL
            await axios.post('https://api.telegram.org/bot1524816097:AAEg9TRy49FIBAO2jsy_tGSUJjcqILL4Jbk/sendMessage',{ chat_id:"-1001616856704", text:txt })
          }else if(target=='Apoteker'){ // GROUP APOTEKER
            await axios.post('https://api.telegram.org/bot1524816097:AAEg9TRy49FIBAO2jsy_tGSUJjcqILL4Jbk/sendMessage',{ chat_id:"-1001633702854", text:txt })
          }else if(target=='Admin'){ // GROUP ADMIN
            await axios.post('https://api.telegram.org/bot1524816097:AAEg9TRy49FIBAO2jsy_tGSUJjcqILL4Jbk/sendMessage',{ chat_id:"-1001692707812", text:txt })
          }
          that.$forceUpdate();
        }
      },
      test(){
          this.sendNotif('test','Apoteker');
      }
    },
}
</script>